
// import Image from "@/domain/model/image/Image.js";
// import Equipment from "@/domain/model/vehicle/Vehicle.js";
// import ChecklistListFilter from '@/domain/model/checklist/ChecklistListFilter.js';
// import ChecklistMap from '@/domain/model/checklist/CheckListMap.js';

// import ContentUtils from '@/utils/ContentUtils.js';
// import ConstUtils from "@/utils/ConstUtils.js";
// import StringUtils from '@/utils/StringUtils.js';
import AddViewModel from "../add/AddViewModel";
import UpdateViewUtils from "./UpdateViewUtils";

export default class UpdateViewModel extends AddViewModel {
  
  constructor(panel, equipment) {
    super(panel, equipment);
  }

  buildRowData(tableData) {
    var utils = new UpdateViewUtils(this, tableData);
    utils.start();
    return this;
  }

}