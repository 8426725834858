

import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
// import IntegerUpdateRow from "@/portals/shared/cell/integer/IntegerUpdateRow.js";

import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
// import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
// import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow.js";

import PictureUpdateRow from "@/portals/shared/cell/picture/PictureUpdateRow.js";
// import CancellingDisplayRow from "@/portals/shared/cell/cancelling/CancellingDisplayRow.js";
import ChecklistUpdateRow from "@/portals/shared/cell/checklist/update/ChecklistUpdateRow.js";

import MC from "@/domain/session/MC.js";
import Equipment from "@/domain/model/vehicle/Vehicle.js";

// import StringUtils from '@/utils/StringUtils.js';
// import ContentUtils from '@/utils/ContentUtils.js';

export default class AddViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this._equipment = model.equipment();
  }

  equipment() {
    return this._equipment;
  }
  
  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      super.start("");
      return;
    }

    if (this.model().isStateContentSaving()) {
      this.renderSaving();
      return;
    }

    if (this.model().isStateContentSaved()) {
      this.renderSaved();
      return;
    }

    this.renderHeader();

    if (this.model().isStateContentLoading()) {
      super.start("Loading Equipment Properties");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter();
  } 

  renderSaving() {
    let box = new BoxDisplayRow()
      .withBorder(true)
      .withChild(new StringDisplayRow().withValue("Saving"));
    this.addBody(box);
  }

  renderSaved() {
    let box = new BoxDisplayRow()
      .withBorder(true)
      .withChild(new StringDisplayRow().withValue("Saved"));
    this.addBody(box);
  }

  renderBody() {
    var images = this.equipment().images().find();
    var image = images.first();
    var equipment = this.equipment();
    
    let body = new BoxDisplayRow()
      .withBorder(true);
    
    let equipmentRow = new RowDisplayRow();
    equipmentRow
      .withChild(new BoxDisplayRow()
                      .withWidth(10)
                      .withChild(new BoxDisplayRow().withBorder(true)
                            .withChild(new TitleDisplayRow().withSubtitle(true).withTitle("Details"))
                            .withChild(
                              new StringUpdateRow()
                              .withName("Name")
                              .withField(Equipment.FIELDS.NAME)
                              .withDefaultValue(equipment.name())
                              .withMandatoryValue(true)))
                      .withChild(new BoxDisplayRow()
                                      .withBorder(true)
                                      .withChild(
                                        new TitleDisplayRow()
                                          .withSubtitle(true)
                                          .withTitle("Checklist")
                                      )
                                      .withChild(
                                        new StringDisplayRow()
                                          .withValue("")
                                      )
                                      .withChild(
                                        new ChecklistUpdateRow()
                                          .withName("Checklists")
                                          .withField(Equipment.FIELDS.CHECKLIST_MAP)
                                          .withMandatoryValue(true)
                                          .withDefaultValue(equipment.checklists().find().first()),
                                      ))
                      .withChild(new BoxDisplayRow().withWidth(15).withBorder(true)
                                        .withChild(new TitleDisplayRow()
                                                        .withSubtitle(true)
                                                        .withTitle(this.MC.Vehicle.Add.NotesLabel.value()))
                                        .withChild(new StringDisplayRow()
                                                        .withAlignLeft()
                                                        .withValue("Add any additional information about this piece of equipment. This will not be displayed on the mobile app."))
                                        .withChild(new TextAreaUpdateRow()
                                                        .withName("Notes")
                                                        .withRows(4)
                                                        .withDefaultValue(equipment.notes())
                                                        .withField(Equipment.FIELDS.NOTES))))
      .withChild(new BoxDisplayRow().withWidth(5)
                        .withChild(new BoxDisplayRow()
                                        .withBorder(true)
                                        .withChild(new TitleDisplayRow().withSubtitle(true).withTitle("Picture"))
                                        .withChild(new PictureUpdateRow()
                                                        .withDefaultValue(image)
                                                        .withField(Equipment.FIELDS.IMAGE_DATA)))
                        .withChild(new BoxDisplayRow()
                                      .withBorder(true)
                                      .withChild(new TitleDisplayRow()
                                                    .withTitle("State and Status")
                                                    .withSubtitle(true))
                                      .withChild(new DefectTypeDisplayRow()
                                                    .withValue(this.equipment().defectType())
                                                    .withLabel("Status"))
                                      .withChild(new StateDisplayRow()
                                                    .withValue(this.equipment().isNew() ? "active" : this.equipment().state())
                                                    .withLabel("State"))))
    ;

    var startEndRow = new BoxDisplayRow()
      .withBorder(true)
      .withWidth(5)
      .withChildren([
        new TitleDisplayRow()
          .withSubtitle(true)
          .withTitle(this.MC.Vehicle.Add.ServiceStartAndEndTitle.value()),
        new DateUpdateRow()
          .withName(this.MC.Vehicle.Add.StartDateLabel.value())
          .withHint("The day the equipment started operations")
          .withDefaultValue(this.equipment().startDate())
          .withField(Equipment.FIELDS.START_DATE),
        new DateUpdateRow()
          .withName(this.MC.Vehicle.Add.EndDateLabel.value())
          .withHint("The day the equipment stopped operations")
          .withDefaultValue(this.equipment().endDate())
          .withField(Equipment.FIELDS.END_DATE),
      ]);

    var vinBox = new BoxDisplayRow()
      .withBorder(true)
      .withWidth(5)
      .withChild(new TitleDisplayRow()
                    .withSubtitle(true)
                    .withTitle("Serial Number and Last Safety Date"))
      .withChild(new DateUpdateRow()
                    .withName(this.MC.Vehicle.Add.Pmvi.value())
                    .withHint(this.MC.Vehicle.Add.PmviHint.value())
                    .withDefaultValue(this.equipment().annualDate())
                    .withField(Equipment.FIELDS.ANNUAL_DATE))
      .withChild(new StringUpdateRow()
                    .withName("Serial Number")
                    .withDefaultValue(this.equipment().vin())
                    .withField(Equipment.FIELDS.VIN));
    
    var odometerRow = new BoxDisplayRow()
      .withBorder(true)
      .withWidth(5)
      .withChildren([
        new TitleDisplayRow()
          .withSubtitle(true)
          .withTitle("Hours, Make, Model, Colour"),

        new StringUpdateRow()
          .withName("Hours")
          .withDefaultValue(this.equipment().odometer())
          .withField(Equipment.FIELDS.ODOMETER),

        new StringUpdateRow()
          .withName("Year")
          .withDefaultValue(this.equipment().year())
          .withField(Equipment.FIELDS.YEAR),

        new StringUpdateRow()
          .withName(this.MC.Vehicle.Add.Summary.value())
          .withHint("Common name for equipment")
          .withDefaultValue(this.equipment().summary())
          .withField(Equipment.FIELDS.SUMMARY),

        new StringUpdateRow()
          .withName(this.MC.Vehicle.Add.Make.value())
          .withHint("John Deere, Kubota, Honda etc")
          .withDefaultValue(this.equipment().make())
          .withField(Equipment.FIELDS.MAKE),

        new StringUpdateRow()
          .withName(this.MC.Vehicle.Add.Model.value())
          .withHint("S140, 2120, BX80 etc")
          .withDefaultValue(this.equipment().model())
          .withField(Equipment.FIELDS.MODEL),

        new StringUpdateRow()
          .withName(this.MC.Vehicle.Add.Colour.value())
          .withDefaultValue(this.equipment().colour())
          .withField(Equipment.FIELDS.COLOUR),

      ]);

    var otherRow = new RowDisplayRow();
    otherRow
      .withChild(startEndRow)
      .withChild(vinBox)
      .withChild(odometerRow)
      .done();

    body.withChild(equipmentRow);
    body.withChild(otherRow);
    this.addBody(body);
  }

  createSaveAnotherRow() {
    if (this.equipment().isNew()) {
      return new ButtonDisplayRow()
        .withBackgroundPrimaryAction()
        .withName("Save and Add Another")
        .withField("SaveAdd")
        .withWidth(3);
    }
    return new SpacerDisplayRow().withWidth(3);
  }

  allowsChecklists() {
    var domain = this.model().domain();
    if (domain) {
      return domain.session().company().find().features().find().allowsCheckLists();
    }
    return false;
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Add Equipment"));
  }

  renderFooter() {
    
    this.addFooter(
      new RowDisplayRow()
      .withBorder(false)
      .withFixedPosition(true)
      .withChildren([
        new SpacerDisplayRow()
          .withWidth(2),

        new ButtonDisplayRow()
          .withWidth(3)
          .withName("Cancel")
          .withField("Close"),

        new SpacerDisplayRow()
          .withWidth(1),

        this.createSaveAnotherRow(),

        new SpacerDisplayRow()
          .withWidth(1),

        new ButtonDisplayRow()
          .withBackgroundPrimaryAction()
          .withName("Save")
          .withField("Save")
          .withWidth(3),

        new SpacerDisplayRow()
          .withWidth(2),

      ])
    );
  }
}