

// import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

// import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
// import IntegerUpdateRow from "@/portals/shared/cell/integer/IntegerUpdateRow.js";

// import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
// import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
// import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
// import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
// import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
// import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
// import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
// import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
// import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
// import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow.js";

// import PictureUpdateRow from "@/portals/shared/cell/picture/PictureUpdateRow.js";
// import CancellingDisplayRow from "@/portals/shared/cell/cancelling/CancellingDisplayRow.js";
// import ChecklistUpdateRow from "@/portals/shared/cell/checklist/update/ChecklistUpdateRow.js";

// import MC from "@/domain/session/MC.js";
// import Equipment from "@/domain/model/vehicle/Vehicle.js";
import AddViewUtils from "../add/AddViewUtils";

// import StringUtils from '@/utils/StringUtils.js';
// import ContentUtils from '@/utils/ContentUtils.js';

export default class UpdateViewUtils extends AddViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Update Equipment"));
  }
}