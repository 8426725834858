<template>
  <AddView :equipmentId="equipmentId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></AddView>
</template>

<script>
import AddView from './UpdateView.vue';
export default {
  name: 'equipment-list-update-row',
  components: {
    AddView
  },
  props: {
    equipmentId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
