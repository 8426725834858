import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
//import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
// import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import VehicleImageDisplayRow from "../../../../../shared/cell/vehicle/image/display/VehicleImageDisplayRow";
import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderEquipment();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderEquipment() {
    var body = new BoxDisplayRow()
      .withBorder(true);
    body
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("2").withTitle(""))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Serial No. and Safety"))
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Extra"))
      );

    var boxContent = new BoxDisplayRow()
    var even = true;
    //var nameFormat = "{0} ({1})";

    var list = this.model().equipment();
    for (var index = 0; index < list.length; index++) {
      let equipment = list[index];
      if (equipment) {
        //
      }
      
      const rowBox = new BoxDisplayRow()
        .withBorder(true)
        .withEven(even);

      const row = new RowDisplayRow()
                .withBorder(false);

      var imageBox = new BoxDisplayRow().withWidth("2");
      imageBox
        .withChild(new VehicleImageDisplayRow().withValue(equipment.data));
      if (!equipment.isStateActive()) {
        imageBox
          .withChild(new StatusDisplayRow()
            .withShowLabel(false)
            .withValue(StringUtils.toString(equipment.state(), "Removed"))
          )
        ;
      }
      var detailsBox = new BoxDisplayRow().withWidth("5");
      detailsBox
        .withChild(new LabelValueDisplayRow().withName("Name/Unit").withValue(equipment.name()))
        .withChild(new StatusDisplayRow()
                        .withLabel("Status")
                        .withValue(StringUtils.toString(equipment.defectType(), "Good")))
        .withChild(new LabelValueDisplayRow().withName("Attributes").withValue(equipment.summary()))
        ;
      detailsBox
          .withChild(new LabelValueDisplayRow().withName("Hours").withValue(equipment.odometer()))
          ;
      if (equipment.checklists().isNotEmpty()) {
        detailsBox
          .withChild(new LabelValueDisplayRow()
                          .withName("Checklist")
                          .withValue(equipment.checklists().first().find().name())
          );
      }

      var safetyBox = new BoxDisplayRow().withWidth("5");
      safetyBox
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Serial No").withValue(equipment.vin()))
        ;
      if (equipment.isLastSafetyDateValid()) {
        var safetyDateDisplay = equipment.annualDateV2().displayAs_YYYY_MM_DD();
        if (equipment.hasLastSafetyDateExpired()) {
          safetyBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Last Safety Date").withValue("Expired").withObject(safetyDateDisplay).withHint("Expired"))
          ;
        } else if (equipment.willLastSafetyDateExpireInWeeks(4)) {
          safetyBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Last Safety Date").withValue("ExpireSoon").withObject(safetyDateDisplay).withHint("Expiring Soon"))
          ;
        } else if (equipment.willLastSafetyDateExpireInWeeks(1)) {
          safetyBox
            .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Last Safety Date").withValue("ExpireNext").withObject(safetyDateDisplay).withHint("Expiring this week!"))
          ;
        } else {
          safetyBox
            .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Last Safety Date").withValue(equipment.annualDateV2().displayAs_YYYY_MM_DD()))
          ;
        }
      } else {
        safetyBox
          .withChild(new StatusDisplayRow().withLabelWidth("7").withValueWidth("15").withName("Last Safety Date").withValue("ExpireSoon").withObject("Missing"))
        ;
      }
      var extraBox = new BoxDisplayRow().withWidth(3);
      extraBox
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Year").withValue(equipment.year()))
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Make / Model").withValue(this.makeAndModel(equipment)))
        .withChild(new LabelValueDisplayRow().withLabelWidth("7").withName("Colour").withValue(equipment.colour()))
        ;

      var actionsRow = new RowDisplayRow();
      actionsRow
        .withChild(new SpacerDisplayRow().withWidth("9"))
        // .withChild(new ButtonDisplayRow().withWidth("1").withName("Details").withId(equipment.id()).withField("Details"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Inspections").withId(equipment.id()).withField("Inspections"))
        ;

      if (equipment.isStateActive()) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Remove").withId(equipment.id()).withField("Remove"))
          ;
      } else {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Restore").withId(equipment.id()).withField("Restore"))
          ;
      }

      actionsRow
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Update").withId(equipment.id()).withField("Update"))
        ;

      row
        .withChild(imageBox)
        .withChild(detailsBox)
        .withChild(safetyBox)
        .withChild(extraBox)
        ;

      rowBox
        .withChild(row)
        .withChild(actionsRow)
        .done()
      boxContent.withChild(rowBox);
      even = !even;
    } 

    if (list.length == 0) {
      // Nothing matches
    }

    body.withChild(boxContent).done();
    this.add(body);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("3").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("8"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("11"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Add Equipment").withField("Add").withBackgroundPrimaryAction())
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new LabelDisplayRow().withWidth("1").withLabel("State"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("all").withField("State").withDefaultValue("all").withSelected(query["state"] === "all"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("active").withField("State").withDefaultValue("active").withSelected(query["state"] === "active" || !query["state"]))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Removed").withKey("removed").withField("State").withDefaultValue("inactive").withSelected(query["state"] === "inactive"))
            )
            .withChild(new RowDisplayRow().withBorder(false)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
              .withChild(new StringUpdateRow().withWidth("5")
                  .withShowLabel(false).withField("Search")
                  .withKey("Search").withName("Search")
                  .withDefaultValue(query["search"] ? query["search"] : ""))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
              .withChild(new SpacerDisplayRow().withWidth("7"))
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    // if (!user.isNew()) {
    //   var box = new BoxDisplayRow().withBorder(true);
    //   var title = StringUtils.format("Inspections for {0}", [user.fullName()]);
    //   box.withChild(new RowDisplayRow()
    //       .withBackgroundTitle()
    //       .withChild(new TitleDisplayRow().withWidth("15").withTitle(title))
    //     );
    //   this.add(box);
    // }
  }

  makeAndModel(equipment) {
    var value = "";
    var makeSet = false;
    if (StringUtils.isNotEmpty(equipment.make())) {
      value = equipment.make();
      makeSet = true;
    }
    if (StringUtils.isNotEmpty(equipment.model())) {
      if (makeSet) {
        value = value + " / " + equipment.model();
      } else {
        value = "<Not Set>" + "/ " + equipment.model();
      }
    } else {
      if (makeSet) {
        value = value + " / " + "<Not Set>"
      }
    }
    return value;
  }
}