
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import AddViewUtils from "./AddViewUtils";

import Equipment from "@/domain/model/vehicle/Vehicle.js";
import ChecklistListFilter from '@/domain/model/checklist/ChecklistListFilter.js';
import ChecklistMap from '@/domain/model/checklist/CheckListMap.js';

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class AddViewModel extends RcTableViewModel {
  
  static Fields = {
      SaveButton: "save",
      SaveAnotherButton: "saveAnother",
      CancelButton: "cancel",
      CancelConfirm: "cancelConfirm",
  }

  constructor(panel, equipment) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._equipmentId = panel.$route.query.equipmentId;
    this._total = 0;
    this._equipment = equipment;
    this._checklistDataLoaded = false;
    this._savedAnother = false;
  }

  start() {
    super.start();
  }

  isAdmin() {
    return this.panel().isAdmin;
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  withValue(valueData) {
    super.withValue(valueData);

    if (!valueData) {
      return;
    }

    this.panel().showMissingRequirements = false;
      
    var field = valueData['field'];
    var fieldValue = valueData['value'];
    var objectId = valueData['id'];
    if (!objectId) {
      return;
    }

    if (field === Equipment.FIELDS.CHECKLIST_MAP) {
      if (StringUtils.isNull(fieldValue)) {
        this._equipment.withChecklists(new ChecklistMap(this.domain()));
      } else {
        var checklist = this.domain().checklists().findById(fieldValue);
        this._equipment.withChecklists(checklist.pointer().map()).done();
      }

    } else if (field == Equipment.FIELDS.START_DATE) {
      if (StringUtils.isNull(fieldValue)) {
        this._equipment.withStartDate(0);
      } else {
        this._equipment.put(field, fieldValue);
      }

    } else if (field == Equipment.FIELDS.END_DATE) {
      if (StringUtils.isNull(fieldValue)) {
        this._equipment.withEndDate(0);
      } else {
        this._equipment.put(field, fieldValue);
      }

    } else if (field == "picture") {
      this._equipment.withImageData(fieldValue);

    } else {
      this._equipment.put(field, fieldValue);   
    }

    this.log().info("{0}", [JSON.stringify(this._equipment.data, null, 2)]);   
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
    if (buttonData["field"] === "Close") {
      buttonData["refresh"] = this._savedAnother;
      this.panel().$emit("withButtonPressed", buttonData);
    }

    if (buttonData["field"] === "Save") {
      if (!this.areRequirementsMet()) {
        this.panel().showMissingRequirements = true;
      } else {
        this.withStateContentSaving();
        this.panel().reloadPage();
        this._equipment
          .withCompany(this.company().pointer())
          .with_Category("equipment")
          .withStateActive()
          ;

        this.event = this.domain().events().vehicleSave(this._equipment.data);
        this.event.send(this.savedListener, this);
      }
    }

    if (buttonData["field"] === "SaveAdd") {
      if (!this.areRequirementsMet()) {
        this.panel().showMissingRequirements = true;
      } else {
        this.withStateContentSaving();
        this.panel().reloadPage();
        this._equipment.withCompany(this.company().pointer());
        this._equipment.withCategory("equipment");
        this.event = this.domain().events().vehicleSave(this._equipment.data);
        this.event.send(this.savedAnotherListener, this);
      }
    }
  }

  savedAnotherListener(response, context) {
    if (!response) {
      return;
    }
    context.withStateContentSaved();
    context.panel().reloadPage();
    this._savedAnother = true;
    var model = context;
    model.log().info("Equipment saved");
    setTimeout(function() {
      model.resetPage(model);
    }, 500);
  }

  resetPage(model) {
    model._equipment = new Equipment(model.domain());
    model.withStateContentLoaded();
    model.panel().reloadPage();
  }
  
  closePage(model) {
    model.panel().close("refresh");
  }

  savedListener(response, context) {
    if (!response) {
      return;
    }
    
    context.withStateContentSaved();
    context.panel().reloadPage();

    var model = context;
    model.log().info("Equipment saved returning");
    setTimeout(function() {
      model.closePage(model);
    }, 500);
  }

  buildRowData(tableData) {
    var utils = new AddViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadData() {
    super.loadItems();
    this
      .withStateContentLoading()
      .clearAndBuildTableData()
      .done();

    this._checklistDataLoaded = false;
    
    this.log().info("Loading view data");
    
    var filter = new ChecklistListFilter();
    filter
      .withPagination(0, 100)
      .done();

    filter
      .withCompany(this.company().pointer())
      .done();
    
    var checklistsEvent = this.domain().events().checklists().list(filter);
    checklistsEvent.send(this.loadedData, this);
  }

  loadedData(response, context) {
    if (response) {
      //
    }
    var model = context;
    model.log().info("Loaded checklist view data");

    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  equipment() {
    return this._equipment;
  }

  areRequirementsMet() {
    var met = true;
    var equipment = this._equipment;

    if (StringUtils.isEmpty(equipment.name())) {
      met = false;
    }

    if (met && equipment.checklists().isEmpty()) {
      met = false
    }
    return met;
  }
}