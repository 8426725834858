<template>
    <Box>
  
      <Row>
        <Column :width="15">
          <Box :key="redraw">
            <AddRow v-if="this.showDialog == 'add'" :equipmentId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></AddRow>
            <UpdateRow v-if="this.showDialog == 'update'" :equipmentId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></UpdateRow>
            <RemoveConfirmDialogRow v-if="this.showDialog == 'remove'" :equipmentId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RemoveConfirmDialogRow>
            <RestoreConfirmDialogRow v-if="this.showDialog == 'restore'" :equipmentId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RestoreConfirmDialogRow>

            <WorkingDisplayRow v-if="this.showWorking"></WorkingDisplayRow>
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
// import DetailsDisplayRow from './details/DetailsDisplayRow.vue';
// import EmailDisplayRow from "./email/EmailDisplayRow.vue";
// import RemarksDisplayRow from "./remarks/RemarksDisplayRow.vue";
import WorkingDisplayRow from '../../../../../shared/views/working/WorkingDisplayRow.vue';
import AddRow from '../add/AddRow.vue';
import UpdateRow from '../update/UpdateRow.vue';
import RemoveConfirmDialogRow from '../remove/RemoveConfirmDialogRow.vue';
import RestoreConfirmDialogRow from '../restore/RestoreConfirmDialogRow.vue';

export default {
    name: "equipment-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      AddRow,
      RemoveConfirmDialogRow,
      RestoreConfirmDialogRow,
      UpdateRow,
      WorkingDisplayRow,
    },
    props: {
      isAdmin: { type: Boolean, default: false },
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        showMissingRequirements: false,
        showDetails: false,
        showEmail: false,
        showRemarks: false,
        showWorking: false,
        selectedId: "",
        showDialog: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },

      $route (to, from){
        if (to && from) {
          if (to.query["action"] === "add") {
            this.showDialog = "add";

          } else if (to.query["action"] === "update") {
            this.selectedId = to.query["equipmentId"];
            this.showDialog = "update";

          } else if (to.query["action"] === "update") {
            this.selectedId = to.query["equipmentId"];
            this.showDialog = "update";

          } else if (to.query["action"] === "remove") {
            this.selectedId = to.query["equipmentId"];
            this.showDialog = "remove";

          } else if (to.query["action"] === "restore") {
            this.selectedId = to.query["equipmentId"];
            this.showDialog = "restore";

          } else {
            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = "";

            } else {
              this.reloadPage();
            }
          }
        }
      }
    },

    mounted: function () {
      this.viewModel = new this.ListViewModel(this);
      this.viewModel.start();
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      showDialogIfRequired: function() {
        var query = this.urlQueryParams();
        if (StringUtils.isNotEmpty(query["action"])) {
          this.selectedId = query["equipmentId"];
          this.showDialog = query["action"];
        }
      },

      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        const value = valueData["value"];
        if (value && value != "null") {
          query[key] = value;
          if (key === "pageSize") {
            delete query.page
          }
        } else {
          delete query[key];
        }
        this.$router.push({ query });
      },

      withValue: function(valueData) {
        this.model().withValue(valueData);

        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData);
          this.addQueryParams(valueData, "pageSize");
        }
        if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");
        }
        if (valueData["field"] === "Search") {
          this.model().withSearchText(valueData);
        }
        
      },

      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);

        if (buttonData["field"] === "Add") {
          const query = this.urlQueryParams();
          query["action"] = "add";
          this.$router.push({ query });
        }
        
        if (buttonData["field"] === "Update") {
          const query = this.urlQueryParams();
          query["action"] = "update";
          query["equipmentId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Inspections") {
          const fullParams = {
            vehicleId: this.Contents.baseEncode(buttonData["id"]),
          };
          let listPath = StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.viewModel.company().idEncoded()]);
          this.$router.push({
            path: listPath,
            query: fullParams,
          });
        }

        if (buttonData["field"] === "Remove") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "remove";
          query["equipmentId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Restore") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "restore";
          query["equipmentId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Refresh") {
          this.reloadPage();
        }

        if (buttonData["field"] === "State") {
          this.addQueryParams(buttonData, "state");
        }

        // BUILT-IN ACTIONS
        if (buttonData["field"] === "Details") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "details";
          query["equipmentId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Close") {
          var query = Object.assign({}, this.$route.query);
          query = this.deleteDetails(query);
          this.$router.push({ query });
          if (buttonData["removed"] || buttonData["refresh"]) {
            this.reloadPage();
          }
        }

        if (buttonData["field"] === "NextPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "PreviousPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");
        }

        if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");
        }

      },
      
      deleteListener: function(response) {
        this.showWorking = false;
        if (response.hasError()) {
          //
        } else {
          this.reloadPage();
        }
      },

      deleteDetails(query) {
        delete query.action;
        delete query.equipmentId;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },

      delayedClearAndBuildTable: function() {
        setTimeout(this.clearAndBuildData, 500);
      },

      clearAndBuildData: function() {
        this.model()
          .withStateContentLoaded()
          .clearAndBuildTableData()
          .done();
        this.showDialogIfRequired();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>